import { connect } from 'react-redux';

import NavBar from 'Layout/NavBar/NavBar';
import { toggleLoginModal } from 'Redux/ducks/modal';

const mapStateToProps = ({
  auth: { authenticated, isAdminLoggedInAs, email, email_newsletter: emailNewsletter },
  router: { location },
  flashSale,
  router: { location: { pathname } },
  siteTheme,
  siteBrand,
}) => ({
  authenticated,
  isAdminLoggedInAs,
  email,
  location,
  flashSale,
  pathname,
  siteTheme,
  siteBrand,
  emailNewsletter,
});

export default connect(
  mapStateToProps,
  { toggleLoginModal },
)(NavBar);
